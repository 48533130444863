import React, { useEffect } from 'react';
import './AppModal.scss';
import Button from '../Button/Button';
import CloseIcon from '~/helpers/js_svg/close-icon';


function AppModal({
  isOpen,
  onClose = () => {},
  children,
  onConfirm = () => {},
  disableConfirm = false,
  customStyles = {},
  showButtons = true,
  showPrimaryCloseButton = false
}) {

  useEffect(() => {
    const handleEscapeKey = (e) => {
      if (isOpen && e.key === 'Escape') {
        onClose();
      }
    };

    const handleClickOutside = (e) => {
      if (isOpen && !e.target.closest('.app-modal-content')) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('keydown', handleEscapeKey);
      document.addEventListener('mousedown', handleClickOutside);

      return () => {
        document.removeEventListener('keydown', handleEscapeKey);
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, [isOpen, onClose]);

  if (!isOpen) {
    return <></>
  };

  return (
    <div className="app-modal-overlay">
      <div className="app-modal-content" style={customStyles}>
        <button className="app-modal-close" onClick={onClose}>
          <CloseIcon  customStyles={{ color: '#353535' }}/>
        </button>
        <div className="app-modal-content-body">

          <div>
            {children}
          </div>

          {
            showButtons && (
              <div className="app-modal-actions-buttons">
                <Button appareance="ghost" onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  color="red"
                  disabled={disableConfirm}
                  onClick={onConfirm}
                >
                  Guardar
                </Button>
              </div>
            )
          }

          {
            showPrimaryCloseButton && (
              <div className="app-modal-actions-buttons" style={{ marginTop: '32px', marginBottom: '0px' }}>
                <Button
                  color="red"
                  onClick={onClose}
                >
                  Cerrar
                </Button>
              </div>
            )
          }

        </div>
      </div>
    </div>
  );
}

export default AppModal;
