import React from 'react';

export const BellIcon = ({ styles = {} }) => {

  const defaultStyles = {
    width: '1em',
    height: '1em',
    verticalAlign: 'middle',
    fill: 'black',
    overflow: 'hidden'
  };

  return (
    <svg style={{ ...defaultStyles, ...styles}} xmlns="http://www.w3.org/2000/svg" class="svg-icon" viewBox="0 0 1024 1024" version="1.1">
      <path d="M875.52 648.96l-76.8-77.226667V381.44a292.693333 292.693333 0 0 0-248.32-293.546667 287.573333 287.573333 0 0 0-325.12 284.586667v199.253333l-76.8 77.226667A69.973333 69.973333 0 0 0 197.973333 768H341.333333v14.506667A163.84 163.84 0 0 0 512 938.666667a163.84 163.84 0 0 0 170.666667-156.16V768h143.36a69.973333 69.973333 0 0 0 49.493333-119.04zM597.333333 782.506667A80.213333 80.213333 0 0 1 512 853.333333a80.213333 80.213333 0 0 1-85.333333-70.826666V768h170.666666zM235.093333 682.666667l50.346667-50.346667a85.333333 85.333333 0 0 0 25.173333-60.586667V372.48A201.813333 201.813333 0 0 1 379.733333 220.586667 199.253333 199.253333 0 0 1 539.306667 170.666667a207.36 207.36 0 0 1 174.08 209.066666v192a85.333333 85.333333 0 0 0 24.746666 60.586667L788.906667 682.666667z"/>
    </svg>
  )

};