"use client";
import React, { useEffect, useState } from "react";
import '../../sass/modules/_renewalReminder.scss';
import { BellIcon } from "~/helpers/js_svg/bell-icon";
import CloseIcon from "~/helpers/js_svg/close-icon";
import { observer } from "mobx-react";
import { withRouter } from "#/renderer/router";
import useStores from "~/store/StoreHook";
import AppModal from "../AppModal/AppModal";
import Button from "../Button/Button";
import prodHelper from '~/helpers/product';

const useResizeDebounced = (delay) => {
  const [windowSize, setWindowSize] = useState({
    width: typeof window !== 'undefined' ? window.innerWidth : 0,
    height: typeof window !== 'undefined' ? window.innerHeight : 0,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    const debouncedHandleResize = debounce(handleResize, delay);

    window.addEventListener('resize', debouncedHandleResize);

    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  }, [delay]);

  return windowSize;
};


const debounce = (func, delay) => {
  let timeoutId;
  return function (...args) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func.apply(this, args), delay);
  };
};

const getName = (item, onlyPlan) => {

  const attributesMap = prodHelper.convertAttributes(item?.data?.attributes);
  const itemName      = (onlyPlan && attributesMap['attr-conf-nombre-plan-sin-equipo']) || item.data.locales[process.env.NEXT_PUBLIC_LOCALE].name;
  const nameResult    = itemName.replace(/<br>/, '\n').replace(/<br\/>/, '\n').replace(/\\n/, '\n').split('\n')[0];

  return nameResult;

}

const RenewalReminder = (props) => {

  const [ order, setOrder ] = useState(null);
  const { renewalStore, accountStore } = useStores();

  const [ renewalsConfig, setRenewalsConfig ] = useState(null);
  const windowSize = useResizeDebounced(300);

  useEffect(() => {

    handleExistingRenewalOrderValidation();

  }, []);

  const handleExistingRenewalOrderValidation = async () => {

    const dataPerson = await accountStore.dataPerson();


    if (dataPerson && dataPerson.success) {

      const currentOrder = await renewalStore.getRenewalOrder();
      console.log('currentOrder', currentOrder);

      if (currentOrder.success) {

        const isDesktop = windowSize.width > 768;
        const newReminderType = isDesktop ? 'DESKTOP' : 'MOBILE';

        setOrder(currentOrder.data.data);
        renewalStore.handleExistsRenewalOrderValidation(true, newReminderType);

      } else {
        renewalStore.handleExistsRenewalOrderValidation(false, null);
      }

    }



  }

  useEffect(() => {

    renewalStore.getConfig().then(data => {
      if (data) {
        setRenewalsConfig(data);
      }

      // setTimeout(() => {
      //   window.addEventListener('click', (e) => {
      //     if (e.target.id == 'desktop-reminder') {
      //       goToRenewal();
      //     }
      //   })
      // }, 500);
    })

  }, []);

  useEffect(() => {

    const isDesktop = windowSize.width > 768;
    const newReminderType = isDesktop ? 'DESKTOP' : 'MOBILE';

    renewalStore.changeReminderType(newReminderType);

  }, [windowSize.width, windowSize.height]);

  const goToRenewal = () => {

    renewalStore.closeReminder();
    props.history.push('/renewal-checkout/address');

  }

  const hasRenewalOrder = renewalStore.hasRenewalOrder;
  const showReminder = renewalStore.showReminder;
  const reminderType = renewalStore.reminderType;
  const isDesktop = reminderType === 'DESKTOP';

  const handleCloseReminder = () => {
    console.log('close reminder');
    renewalStore.closeReminder();
  }

  if (!hasRenewalOrder || !showReminder || !renewalsConfig) {
    return <></>
  }


  if (isDesktop) {

    const isHome = window.location.pathname === '/';
    const marginStyles = isHome ? {  } : {};

    return (
      <div className="renewal-reminder" style={marginStyles}>
        <BellIcon styles={{ fill: 'white', width: '24px', height: '24px' }}/>
        <div dangerouslySetInnerHTML={{ __html: renewalsConfig.texts.desktop_reminder }}></div>
        <div className="renewal-reminder--close" onClick={handleCloseReminder}>
          <CloseIcon customStyles={{ width: '20px', height: '20px' }}/>
        </div>
      </div>
    )
  }


  const items = order ? order?.items || [] : [];

  const onlyPlan = order ? order?.renewal_info?.onlyPlan       : false;
  const terminal  = order ? items.find(it => it.data.type === '6') : {};
  const planItem  = order ? items.find(it => it.data.type === '2') : {};
  const terminalName = order ? (!onlyPlan ? getName(terminal, onlyPlan) : "") : "";
  const planName     = order ? getName(planItem, onlyPlan) : "";



  const plan = planName + terminalName
  const content = renewalsConfig.texts.mobile_reminder.content?.replace('{{plan}}', plan);
  const mobileReminderTxt = renewalsConfig.texts.mobile_reminder;

  return (
    <AppModal
      isOpen={true}
      showButtons={false}
      onClose={handleCloseReminder}
      customStyles={{ width: 'calc(100vw - 32px)', maxWidth: '400px' }}
      >
      <div className="renenwal-reminder-dialog">
        <div className="renenwal-reminder-dialog--image">
          <img src={`${process.env.NEXT_PUBLIC_ESPOTS}/${mobileReminderTxt.image}`} alt="bell"/>
        </div>
        <div className="renenwal-reminder-dialog--content">
          <p dangerouslySetInnerHTML={{ __html: content }}></p>
        </div>

        <div className="renenwal-reminder-dialog--buttons">
          <Button onClick={goToRenewal} color="red">
            {mobileReminderTxt.continue_button}
          </Button>

          <Button appareance="ghost" color="red" onClick={handleCloseReminder}>
            {mobileReminderTxt.cancel_button}
          </Button>
        </div>
      </div>
    </AppModal>
  )


}

export default withRouter(observer(RenewalReminder));
